/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./filtering.css";
import { useDispatch, useSelector } from "react-redux";
import { UsersListAction } from "../../../../store/actions/UsersAction";
import toast from "react-hot-toast";
import {
  AssignPropertyToUser,
  getPropertyDetails,
} from "../../../../services/PropertyService";
import { useNavigate } from "react-router-dom";

const SelectUserTable = () => {
  const dispatch = useDispatch();
  const { usersList, loading } = useSelector((state) => state.users);
  const nav = useNavigate();
  const [initialSelectedUsers, setInitialSelectedUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    dispatch(UsersListAction("all"));
    getPropertyDetails(path)
      .then((res) => {
        const initialUsers = res.data?.property?.users || [];
        setInitialSelectedUsers(initialUsers.map((user) => user._id));
        setSelectedUserIds(initialUsers.map((user) => user._id));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, path]);

  const handleSelectionChange = (userId) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };

  const filteredUsers = usersList?.users?.filter((user) => {
    return (
      user?.user?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.user?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.user?.role.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const onSubmitUser = () => {
    setIsLoading(true);
    if (selectedUserIds.length === 0) {
      setIsLoading(false);
      return toast.error("Select at least one user");
    }

    const postData = {
      users: selectedUserIds,
      property: path,
    };

    console.log(postData);

    if (!postData.property) {
      setIsLoading(false);
      return toast.error("Select a property");
    }

    AssignPropertyToUser(postData)
      .then((res) => {
        toast.success("User Assigned Successfully");
        nav("/property-list");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card-header">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <div
            className="d-flex gap-4 align-items-center"
            style={{ width: "350px" }}
          >
            <h4 className="card-title">Search</h4>
            <input
              type="text"
              className="form-control input-rounded"
              placeholder="Search by Name, Number, or ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="text-end toolbar toolbar-bottom p-2">
            <button
              className={`${
                selectedUserIds.length > 0 ? "" : "disabled"
              } btn btn-success px-4`}
              onClick={() => onSubmitUser()}
            >
              Assign
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="w-100 table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th></th> {/* Checkbox column */}
                <th>User Name</th>
                <th>User Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user) => (
                <tr
                  key={user?.user?._id}
                  onClick={() => handleSelectionChange(user?.user?._id)}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedUserIds.includes(user?.user?._id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectionChange(user?.user?._id);
                      }}
                    />
                  </td>
                  <td>{user?.user?.name}</td>
                  <td>{user?.user?.email}</td>
                  <td>{user?.user?.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SelectUserTable;
