import axiosInstance from "./AxiosInstance";

export function getPropertyList(pageNo) {
  return axiosInstance.get(`/api/property?page=${pageNo}`);
}

export function getPropertyDetails(id) {
  return axiosInstance.get(`/api/property/${id}`);
}

export function getPropertyInitialExpense(id, pageNo) {
  return axiosInstance.get(
    `/api/property/initial-expenses?page=${pageNo ? pageNo : 1}&id=${id}`
  );
}

export function AssignPropertyToUser(postData) {
  return axiosInstance.post(`/api/property/assign-users`, postData);
}

export function EditProperty(postData) {
  return axiosInstance.post(`/api/property/save`, postData);
}

export function DeleteProperty(id) {
  return axiosInstance.get(`/api/property/delete/${id}`);
}

export function freezeProperty(id) {
  return axiosInstance.get(`/api/property/status-change/${id}`);
}
export function onReadyProperty(id) {
  return axiosInstance.get(`/api/property/make-ready/${id}`);
}

export function AddProperty(postData) {
  // const formData = new FormData();
  // console.log("postData", postData);
  return axiosInstance.post(`/api/property/add`, postData);
}

export function addExpense(postData) {
  // const formData = new FormData();
  // console.log("postData", postData);
  return axiosInstance.post(`/api/transactions/expense-add`, postData);
}

export function getUnitsDetails(id) {
  return axiosInstance.get(`/api/property/unit/${id}`);
}

export function getPropertyTransactions(id, pgNo) {
  return axiosInstance.get(`api/property/transactions?page=${pgNo}&id=${id}`);
}

export function getPropertyDeals(id) {
  return axiosInstance.get(`/api/deals/property-deals/${id}`);
}
