import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { Button } from "react-bootstrap";

import noImage from "../../../images/no-image.jpg";

import PageTitle from "../../layouts/PageTitle";
import { PropertyAction } from "../../../store/actions/PropertryActions";
import {
  DeleteProperty,
  freezeProperty,
  onReadyProperty,
} from "../../../services/PropertyService";
import ConfirmPropertyActionModal from "./ConfirmPropertyActionModal";

const PropertyList = () => {
  const [modalCentered, setModalCentered] = useState(false);
  const [modalType, setModalType] = useState([]);
  const [selectedProperty, setselectedProperty] = useState();
  // const [isLoading, setIsLoading] = useState();

  const { properties, loading } = useSelector((state) => state.properties);
  const [pageNo, setPageNo] = useState(1);

  const handlePageChange = (isPrev) => {
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // console.log(properties);

  const nav = useNavigate();

  const dispatch = useDispatch();
  // const [openMenu, setOpenMenu] = useState(true);

  useEffect(() => {
    dispatch(PropertyAction(pageNo));
  }, [dispatch, pageNo]);

  // console.log(properties[92]?.images)
  const propertyList = properties?.data;
  const totalPages = Math.ceil(properties?.total_documents / 12);
  console.log(propertyList);
  // console.log(totalPages);

  const onDelete = (id) => {
    // setIsLoading(true)
    DeleteProperty(id).then((res) => {
      dispatch(PropertyAction());
    });
  };

  const onFreeze = (id) => {
    freezeProperty(id).then((res) => {
      dispatch(PropertyAction());
    });
  };

  const onReady = (id) => {
    onReadyProperty(id).then((res) => {
      dispatch(PropertyAction());
    });
  };

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  // const onFreeze = () => {
  //   setModalCentered(true);
  //   setModalType("freeze_property");
  // };

  return (
    <>
      <PageTitle activeMenu={"Property List"} />

      {/* Modal */}

      {ConfirmPropertyActionModal(
        modalCentered,
        setModalCentered,
        modalType,
        onFreeze,
        selectedProperty,
        onDelete,
        onReady
      )}

      <Tab.Container defaultActiveKey={"Grid"}>
        <div className="widget-stat card ">
          <div className="card-body p-4  gap-4">
            <div className="gap-4 property-card-d">
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => {
                  nav("add-expense");
                }}
              >
                <span className="btn-icon-start text-primary">
                  <i className="fa fa-plus color-primary" />
                </span>
                Add a expense
              </Button>

              <Button
                className="me-2"
                variant="success btn-rounded"
                onClick={() => {
                  nav("add-property");
                }}
              >
                <span className="btn-icon-start text-success">
                  <i className="fa fa-plus color-success" />
                </span>
                Add new property
              </Button>
            </div>
          </div>
        </div>

        <div className="my-4 row">
          <div className="col-sm-6"></div>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey={"Grid"}>
            <div className="row">
              {propertyList?.map((item, ind) => (
                <div
                  className="col-xl-3 col-xxl-4 col-md-6 col-sm-6 col-lg-4 m-b30"
                  key={ind}
                >
                  <div className="property-card style-1">
                    <div
                      className={`dz-media ${!item?.active ? "disabled" : ""} `}
                    >
                      <ul>
                        <li
                          className={`badge  badge-sm ${
                            item?.ready_to_use
                              ? "badge-success"
                              : "badge-warning"
                          }  light`}
                        >
                          {item.ready_to_use
                            ? "Ready to use"
                            : "Not ready to use"}
                        </li>

                        <li className="rent badge badge-sm badge-primary">
                          {item.type}
                        </li>
                      </ul>
                      <img
                        src={`${
                          item?.images && item?.images.length > 0
                            ? item?.images[0]
                            : noImage
                        }`}
                        alt="/"
                      />
                    </div>

                    <div
                      className={`card-body pt-2 pb-3 ${
                        !item?.active ? "disabled" : ""
                      }`}
                    >
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item text-overflow">
                          <span className="mb-0 title">Property name</span> :
                          <span className="text-black ms-2">
                            {item?.property_name}
                          </span>
                        </li>
                        <li className="list-group-item text-overflow">
                          <span className="mb-0 title">Property number</span> :
                          <span className="text-black ms-2">
                            {item?.property_number}
                          </span>
                        </li>
                        <li className="list-group-item text-overflow">
                          <span className="mb-0 title">Location</span> :
                          <a
                            href={item?.location_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="text-black desc-text ms-2">
                              {item?.location}, {item?.city}
                            </span>
                          </a>
                        </li>
                        <li className="list-group-item text-overflow">
                          <span className="mb-0 title">Assigned to</span> :
                          <span className="text-black desc-text ms-2">
                            {item?.users?.map((user) => user.name).join(", ")}
                          </span>
                        </li>
                        <li className="list-group-item text-overflow">
                          <span className="mb-0 title">Rooms</span> :
                          <span className="text-black desc-text ms-2">
                            {item?.units?.length ? item?.units?.length : 0}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="list-group-item flex-row d-flex justify-content-end">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          variant=""
                          className="btn-link i-false"
                        >
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                              stroke="#3E4954"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                              stroke="#3E4954"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                              stroke="#3E4954"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-right">
                          <Dropdown.Item
                            className="text-black"
                            onClick={() => {
                              nav(`property-details/${item._id}`);
                            }}
                          >
                            View property
                          </Dropdown.Item>

                          {item?.active && item?.ready_to_use && (
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => {
                                nav(`property-deals/${item._id}`);
                              }}
                            >
                              View deals
                            </Dropdown.Item>
                          )}

                          {item?.active ? (
                            <>
                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  nav(`edit-property/${item?._id}`);
                                }}
                              >
                                Edit property
                              </Dropdown.Item>

                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  nav(`assign-property/${item?._id}`);
                                }}
                              >
                                Assign user
                              </Dropdown.Item>
                              {item?.ready_to_use && (
                                <>
                                  <Dropdown.Item
                                    className="text-black"
                                    onClick={() => {
                                      setModalType("freeze_property");
                                      setselectedProperty(item?._id);
                                      setModalCentered(true);
                                    }}
                                  >
                                    Freeze property
                                  </Dropdown.Item>
                                </>
                              )}

                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  setModalType("delete_property");
                                  setselectedProperty(item?._id);
                                  setModalCentered(true);
                                }}
                              >
                                Delete property
                              </Dropdown.Item>

                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  if (item?.ready_to_use) {
                                    setModalType("not_ready_to_use");
                                  } else {
                                    setModalType("ready_to_use");
                                  }
                                  setselectedProperty(item?._id);
                                  setModalCentered(true);
                                }}
                              >
                                {item?.ready_to_use
                                  ? "Convert to not ready"
                                  : "Convert to Ready"}
                              </Dropdown.Item>
                            </>
                          ) : (
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => {
                                setModalType("unfreeze_property");
                                setselectedProperty(item?._id);
                                setModalCentered(true);
                              }}
                            >
                              Unfreeze property
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <div className="row">
        <div className="col-xl-12 ">
          <div className="table-responsive table-hover fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {properties?.length} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <div
                    className="paginate_button previous"
                    onClick={() => {
                      if (pageNo > 1) {
                        handlePageChange(true);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-left" />
                  </div>
                  <span>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <div
                        key={i}
                        className={`paginate_button ${
                          pageNo === i + 1 ? "current" : ""
                        }`}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setPageNo(i + 1);
                        }}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </span>
                  <div
                    className="paginate_button next"
                    onClick={() => {
                      if (pageNo < totalPages) {
                        handlePageChange(false);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyList;
