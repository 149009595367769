import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
import { filter, isEqual } from "lodash";
import { updateInvestor } from "../../../services/InvestorsServices";
import EditRentForm from "./EditRentForm";
import { getRent, updateRent } from "../../../services/RentServices";
import { PropertyAction } from "../../../store/actions/PropertryActions";
import { getPropertyList } from "../../../services/PropertyService";

const EditRentCollection = () => {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const UserId = path[path.length - 1];
  const [rent, setRent] = useState({});
  const [oldRent, setOldRent] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true
  const [filteredProperties, setFilteredProperties] = useState([]);

  useEffect(() => {
    //Property list from API
    getPropertyList("all").then((properties) => {
      // console.log(properties);
      const propertyOptions = properties?.data?.data?.map((property) => ({
        value: property?._id,
        label: property?.property_name,
      }));
      setFilteredProperties(propertyOptions);
    });

    // Get User details from API
    getRent(UserId)
      .then((res) => {
        // console.log(res.data.data);
        setOldRent({
          billing_date: formatDate(res?.data?.data?.billing_date),
          amount: res?.data?.data?.amount,
          description: res?.data?.data?.description,
          property_id: res?.data?.data?.property?._id,
        });

        setRent({
          billing_date: formatDate(res?.data?.data?.billing_date),
          amount: res?.data?.data?.amount,
          description: res?.data?.data?.description,
          property_id: res?.data?.data?.property?._id,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // nav("/page-error-400");
        setIsLoading(false);
      });
  }, [UserId, nav]);

  // console.log(filteredProperties);

  const onSubmit = () => {
    setIsLoading(true);

    console.log(rent);

    const hasFormChanges = !isEqual(oldRent, rent);
    if (!hasFormChanges) {
      toast.error(
        "No changes made. Please edit the details before submitting."
      );
      setIsLoading(false);
      return;
    }

    const postData = {
      billing_date: rent?.billing_date,
      amount: rent?.amount,
      description: rent?.description,
      property_id: rent?.property_id,
    };

    updateRent(UserId, postData)
      .then((res) => {
        console.log(res);
        nav("/rent-list");
        toast.success("Rent updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Add leading zero
    const day = `0${d.getDate()}`.slice(-2); // Add leading zero
    return `${year}-${month}-${day}`;
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }
  return (
    <>
      <PageTitle
        activeMenu={"Edit rent"}
        activePath={"edit-rent"}
        motherMenu={"Rent List"}
        motherPath={"rent-list"}
      />
      <EditRentForm
        nav={nav}
        onSubmit={onSubmit}
        rent={rent}
        setRent={setRent}
        properties={filteredProperties}
      />
    </>
  );
};

export default EditRentCollection;
