import axiosInstance from "./AxiosInstance";

export function getRentCollection(searchQuery, pageNo) {
  console.log(
    `/api/transactions/rent ${pageNo && `?page=${pageNo}`}${
      searchQuery && `&search=${searchQuery}`
    }`
  );
  return axiosInstance.get(
    `/api/transactions/rent${pageNo && `?page=${pageNo}`}${
      searchQuery && `&search=${searchQuery}`
    }`
  );
}

export function getRent(id) {
  return axiosInstance.get(`/api/transactions/rent/${id}`);
}

export function updateRent(id, postData) {
  return axiosInstance.post(`/api/transactions/editRent/${id}`, postData);
}
