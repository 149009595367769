/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Button, Modal, Badge } from "react-bootstrap";
import noImage from "../../../images/no-image.jpg";
import { RentAction } from "../../../store/actions/RentActions";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function RentCollectionList() {
  const dispatch = useDispatch();
  const { rents, loading } = useSelector((state) => state.rent);
  const nav = useNavigate();

  const [modalCentered, setModalCentered] = useState(false);
  const [viewDetails, setViewDetails] = useState([]);
  // const [rejectModalCentered, setRejectModalCentered] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("all");
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    dispatch(RentAction(searchQuery, pageNo));
  }, [dispatch, sortBy, searchQuery, pageNo]);

  const rentList = rents.data;
  console.log(rents);

  const toggleViewDetails = (item) => {
    setModalCentered(true);
    setViewDetails(item);
  };

  // Pagination logic

  const totalPage = Math.ceil(rents.total_documents / 10);

  const handlePageChange = (isPrev) => {
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // if (loading) {
  //   return (
  //     <div id="preloader">
  //       <div className="sk-three-bounce">
  //         <div className="sk-child sk-bounce1"></div>
  //         <div className="sk-child sk-bounce2"></div>
  //         <div className="sk-child sk-bounce3"></div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Rent collection</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/rent-list">Rent List</Link>
            </li>
          </ol>
        </div>
        <div className="gap-3 d-flex justify-content-center align-content-center">
          <Link
            onClick={() => window.location.reload()}
            className="btn btn-primary rounded light"
          >
            Refresh
          </Link>

          {/* <ExportButton type={"rent"} />  */}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-6 col-md-6"></div>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body">
                  <h3>{rentList?.length}</h3>
                  <span className="fs-14 text-black">
                    Total number of rents
                  </span>
                </div>
                <span className="bg-primary rounded p-3">
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.1208 37.6042H1.97909C1.10825 37.6042 0.395752 36.8917 0.395752 36.0208V14.1708C0.395752 13.775 0.554085 13.3 0.870752 13.0625L14.0124 0.791659C14.4874 0.395825 15.1208 0.237492 15.6749 0.474992C16.3083 0.791659 16.6249 1.34583 16.6249 1.97916V36.1C16.6249 36.8917 15.9124 37.6042 15.1208 37.6042ZM3.48325 34.5167H13.5374V5.54166L3.48325 14.8833V34.5167Z"
                      fill="white"
                    />
                    <path
                      d="M36.0208 37.6042H15.0416C14.1708 37.6042 13.4583 36.8917 13.4583 36.0208V17.4167C13.4583 16.5458 14.1708 15.8333 15.0416 15.8333H36.0208C36.8916 15.8333 37.6041 16.5458 37.6041 17.4167V36.1C37.6041 36.8917 36.8916 37.6042 36.0208 37.6042ZM16.6249 34.5167H34.5166V18.9208H16.6249V34.5167Z"
                      fill="white"
                    />
                    <path
                      d="M28.5791 37.6042H22.4832C21.6124 37.6042 20.8999 36.8917 20.8999 36.0208V26.3625C20.8999 25.4917 21.6124 24.7792 22.4832 24.7792H28.5791C29.4499 24.7792 30.1624 25.4917 30.1624 26.3625V36.0208C30.1624 36.8917 29.4499 37.6042 28.5791 37.6042ZM24.0666 34.5167H27.0749V27.9458H24.0666V34.5167Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-12">
          <div className="card house-bx">
            <div className="card-body">
              <div className="media align-items-center">
                <svg
                  width={55}
                  height={55}
                  viewBox="0 0 88 85"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M77.25 	30.8725V76.25H10.75V30.8725L44 8.70001L77.25 30.8725Z"
                    fill="url(#paint0_linear)"
                  />
                  <path
                    d="M2 76.25H86V85H2V76.25Z"
                    fill="url(#paint1_linear)"
                  />{" "}
                  <path
                    d="M21.25 39.5H42.25V76.25H21.25V39.5Z"
                    fill="url(#paint2_linear)"
                  />
                  <path
                    d="M52.75 39.5H66.75V64H52.75V39.5Z"
                    fill="url(#paint3_linear)"
                  />
                  <path
                    d="M87.9424 29.595L84.0574 35.405L43.9999 8.70005L3.94237 35.405L0.057373 29.595L43.9999 0.300049L87.9424 29.595Z"
                    fill="url(#paint4_linear)"
                  />
                  <path
                    d="M49.25 62.25H70.25V65.75H49.25V62.25Z"
                    fill="url(#paint5_linear)"
                  />
                  <path
                    d="M52.75 50H66.75V53.5H52.75V50Z"
                    fill="url(#paint6_linear)"
                  />
                  <path
                    d="M28.25 57C28.25 57.4642 28.0656 57.9093 27.7374 58.2375C27.4092 58.5657 26.9641 58.75 26.5 58.75C26.0359 58.75 25.5908 58.5657 25.2626 58.2375C24.9344 57.9093 24.75 57.4642 24.75 57C24.75 56.5359 24.9344 56.0908 25.2626 55.7626C25.5908 55.4344 26.0359 55.25 26.5 55.25C26.9641 55.25 27.4092 55.4344 27.7374 55.7626C28.0656 56.0908 28.25 56.5359 28.25 57Z"
                    fill="url(#paint7_linear)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="19.255"
                      y1="28.8075"
                      x2="64.1075"
                      y2="73.6775"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F9F9DF" />
                      <stop offset={1} stopColor="#B6BDC6" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1={2}
                      y1="80.625"
                      x2={86}
                      y2="80.625"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1="22.9825"
                      y1="40.6025"
                      x2="37.8575"
                      y2="69.915"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F0CB49" />
                      <stop offset={1} stopColor="#E17E43" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear"
                      x1="52.75"
                      y1="51.75"
                      x2="66.75"
                      y2="51.75"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#7BC7E9" />
                      <stop offset={1} stopColor="#3C6DB0" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear"
                      x1="0.057373"
                      y1="17.8525"
                      x2="87.9424"
                      y2="17.8525"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#E17E43" />
                      <stop offset={1} stopColor="#85152E" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear"
                      x1="784.25"
                      y1="216.25"
                      x2="1036.25"
                      y2="216.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint6_linear"
                      x1="570.75"
                      y1="179.5"
                      x2="682.75"
                      y2="179.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint7_linear"
                      x1="98.25"
                      y1="195.25"
                      x2="105.25"
                      y2="195.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#E17E43" />
                      <stop offset={1} stopColor="#85152E" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="media-body">
                  <h4 className="fs-22 text-white">INFORMATION</h4>
                  <p className="mb-0">Rent collection list</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={modalCentered}
          onHide={setModalCentered}
          centered
        >
          <Modal.Header>
            <Modal.Title>Details</Modal.Title>
            <Button
              onClick={() => {
                setModalCentered(false);
              }}
              variant=""
              className="btn-close"
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="row">
              <div
                className="col-12"
                // key={ind}
              >
                <div className="property-card style-1">
                  <div className={`dz-media `}>
                    <Zoom>
                      <img alt="" src={viewDetails?.images?.[0] || noImage} />
                      {/* <Button
                        className="bg-transparent border-0 "
                        // onClick={() =>
                        //   handleImageClick(viewDetails?.images?.[0] || noImage)
                        // }
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      >
                        <i
                          className="fa fa-expand fa-2x text-black"
                          aria-hidden="true"
                        ></i>
                      </Button> */}
                    </Zoom>
                  </div>

                  <div className={`card-body pt-2 pb-3`}>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <span className="mb-0 title">Property</span> :
                        <span className="text-black ms-2">
                          {viewDetails?.property
                            ? viewDetails?.property?.property_name
                            : "No property Available"}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Description</span> :
                        <span className="text-black ms-2">
                          {viewDetails?.description}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <Modal.Title>User details</Modal.Title>
                      </li>

                      <li className="list-group-item">
                        <span className="mb-0 title">Email</span> :
                        <span className="text-black desc-text ms-2">
                          {viewDetails?.user?.email}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Role</span> :
                        <span className="text-black desc-text ms-2">
                          {viewDetails?.user?.role}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Status</span> :
                        <span className="text-black desc-text ms-2">
                          <Badge
                            bg=""
                            className={`badge-${
                              viewDetails?.user?.active ? "success" : "danger"
                            } badge-xs`}
                          >
                            {viewDetails?.user?.active ? "Active" : "Inactive"}
                          </Badge>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="flex-column d-flex justify-content-center">
            <Button
              variant="success"
              onClick={() => {
                setModalCentered(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>

        <div className="col-xl-12  ">
          {/* <Row className="mb-2 w-100 col-12 justify-content-end">
            <div className="d-flex justify-content-end w-100">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="sm"
                  className="mt-1 mb-2 text-uppercase"
                >
                  Sort by : {sortBy}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSortBy("all")}>
                    Sort by : ALL{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortBy("requested")}>
                    Sort by : REQUESTED{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortBy("accepted")}>
                    Sort by : ACCEPTED{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortBy("rejected")}>
                    Sort by : REJECTED{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Row> */}

          {/* Search Input */}
          <div
            className="d-flex  gap-4 align-items-center justify-content-start py-4 "
            style={{ width: "100%" }}
          >
            <h4 className="card-title">Search</h4>

            <input
              type="text"
              className="form-control input-rounded w-50"
              placeholder="Search by Voucher number..."
              value={searchQuery}
              onChange={(e) => {
                setPageNo(1);
                setSearchQuery(e.target.value);
              }}
            />
          </div>

          {loading ? (
            // <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          ) : (
            // </div>
            <div className="table-responsive table-hover fs-14 order-list-table">
              <div id="orderList" className="dataTables_wrapper no-footer">
                <table
                  className="table display table-hover display mb-4 dataTablesCard card-table dataTable no-footer"
                  id="example5"
                >
                  <thead>
                    <tr role="row">
                      {/* <th className='orderList_sorting_asc'>
                                            <div className='form-check custom-checkbox ms-2'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='checkAll'
                                                    required
                                                // onClick={() => chackboxFun('all')}
                                                />
                                                <label
                                                    className='form-check-label'
                                                    htmlFor='checkAll'
                                                />
                                            </div>
                                        </th> */}
                      <th className="">Name</th>
                      <th className="">Billing Date</th>
                      <th className="">Posting Date</th>
                      <th className="">Amount</th>
                      <th className="">Voucher number</th>
                      <th className="">Type</th>
                      {/* <th className=''>Type</th> */}
                      {/* <th className=''>Agent</th> */}
                      <th className="sorting">Room</th>
                      <th className="">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rentList?.length > 0 ? (
                      rentList?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.user?.name}</td>
                          <td>
                            {item?.billing_date
                              ? new Date(item.billing_date).toUTCString()
                              : ""}
                          </td>

                          <td>
                            {item?.posting_date
                              ? new Date(item.posting_date).toUTCString()
                              : ""}
                          </td>

                          {/* <td>{item.customer}</td> */}
                          <td> {item?.amount}</td>
                          {/* <td>{item.location}</td> */}
                          <td>{item?.voucher_number}</td>
                          <td>{item.type}</td>
                          <td>
                            {item.units?.length > 0 ? item.units?.length : 0}
                          </td>
                          {/* <td>
                          <Badge
                            bg=""
                            className={`badge-${
                              item?.status === "rejected"
                                ? "danger"
                                : item?.status === "accepted"
                                ? "success"
                                : item?.status === "requested"
                                ? "warning"
                                : "light"
                            } badge-xs`}
                          >
                            {item?.status}
                          </Badge>
                        </td> */}
                          {
                            <Dropdown className="ms-auto">
                              <Dropdown.Toggle
                                variant=""
                                className="btn-link i-false"
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-right">
                                {/* <Dropdown.Item
                                className="text-black"
                                onClick={() =>
                                  toggleAcceptOrder(
                                    item._id,
                                    item.requested_amount,
                                    item.comment,
                                    item.requested_by.name
                                  )
                                }
                              >
                                Accept order
                              </Dropdown.Item> */}

                                <Dropdown.Item
                                  className="text-black"
                                  onClick={() => toggleViewDetails(item)}
                                >
                                  View details
                                </Dropdown.Item>

                                <Dropdown.Item
                                  className="text-black"
                                  onClick={() => nav(`edit/${item?._id}`)}
                                >
                                  Edit rent
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          }
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No Requests</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* Pagination */}
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {rentList?.length} of {rents?.total_documents}{" "}
                    entries
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                    <Link
                      className="paginate_button previous"
                      to="#"
                      onClick={() => handlePageChange(true)}
                    >
                      <i className="fa fa-angle-double-left" />
                    </Link>
                    <span>
                      {(() => {
                        let pages = [];
                        if (totalPage <= 5) {
                          // If total pages are 5 or less, show all
                          for (let i = 1; i <= totalPage; i++) {
                            pages.push(i);
                          }
                        } else {
                          // Always show first page
                          pages.push(1);

                          // If current page is among first 3 pages
                          if (pageNo <= 3) {
                            for (let i = 2; i <= 5; i++) {
                              pages.push(i);
                            }
                          }
                          // If current page is among last 3 pages
                          else if (pageNo > totalPage - 3) {
                            pages.push("...");
                            for (let i = totalPage - 3; i <= totalPage; i++) {
                              pages.push(i);
                            }
                          }
                          // For all other cases
                          else {
                            pages.push("...");
                            for (let i = pageNo - 1; i <= pageNo + 1; i++) {
                              pages.push(i);
                            }
                            if (pageNo + 2 < totalPage) {
                              pages.push("...");
                            }
                            if (pageNo + 1 < totalPage) {
                              pages.push(totalPage);
                            }
                          }
                        }

                        return pages.map((page, index) => (
                          <Link
                            key={index}
                            to="#"
                            className={`paginate_button ${
                              pageNo === page ? "current" : ""
                            }`}
                            onClick={() => {
                              if (typeof page === "number") {
                                setPageNo(page);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }
                            }}
                          >
                            {page}
                          </Link>
                        ));
                      })()}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="#"
                      onClick={() => {
                        if (rents && totalPage > pageNo) {
                          handlePageChange(false);
                        }
                      }}
                    >
                      <i className="fa fa-angle-double-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RentCollectionList;
