import { getRentCollection } from "../../services/RentServices";

export const RENT_COLLECTION_LIST = "RENT_COLLECTION_LIST";
export const RENT_START = "RENT_START";

export function RentAction(searchQuery, pageNo) {
  return (dispatch) => {
    dispatch({ type: RENT_START });

    getRentCollection(searchQuery, pageNo)
      .then((response) => {
        console.log(response.data);

        const data = response.data;

        dispatch(RentData(data));
      })
      .catch((error) => {
        // dispatch(Logout(navigate));
        // console.log(error);
      });
  };
}

// FundActions.js

export function RentData(data) {
  return {
    type: RENT_COLLECTION_LIST,
    payload: data,
  };
}
