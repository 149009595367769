import { getFundData, updateFundRequest } from "../../services/FundServices";
import toast from "react-hot-toast";

export const FUND_REQUESTS_LIST = "FUND_REQUESTS_LIST";
export const REQUEST_START = "REQUEST_START";

export function FundRequestsAction(status, pageNo) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getFundData(status, pageNo)
      .then((response) => {
        // console.log(response.data);

        const data = response.data;

        dispatch(updateFundRequestsData(data));
      })
      .catch((error) => {
        // dispatch(Logout(navigate));
        // console.log(error);
      });
  };
}

export function updateFundRequests(data) {
  return (dispatch) => {
    updateFundRequest(data)
      .then((response) => {
        console.log(response);

        if (response.data.message !== "Fund request rejected") {
          toast.success("The amount has been sent to the supervisor.");
        } else {
          toast.success("Request rejected successfully.");
        }

        dispatch(FundRequestsAction(data));
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.error}`);
        console.log(error);
      });
  };
}

// FundActions.js

export function updateFundRequestsData(data) {
  return {
    type: FUND_REQUESTS_LIST,
    payload: data,
  };
}
